.navbarContainer{
  background-color: #5C24C9;
  opacity: 99%;
  top: 0%;
  position: fixed;
  width: 100%;
  z-index: 1;
  color: white;
  height: 82px;
}
.navbar{  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  padding-left: 0px;
  height: 100%;
}
.nav{
  line-height: 5vh;
  text-align: center;
  bottom: 10px;
}
.logo{
  display: flex;
  align-items: center;
  margin-left: 80px;
  cursor: pointer;
}
.logo > * {
  align-self: center;
}
.list > * {
  align-self: center;
}
.link{
  color: white;
  text-decoration: none;
  white-space: nowrap;
}
.link > li {
  align-self: center;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
}
nav .link:hover{
  text-decoration: underline 2px;
  text-underline-offset: 180%;
}

.businessLink{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: #FFF;
  text-decoration: none;
  white-space: nowrap;
  width: 113px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1.5px solid #FFF;
  background: #5C24C9;
}
nav .businessLink:hover{
  color: #FFF;
  background: #b196e5;
  border: 1.5px solid #b196e5;
}

nav .businessLink:active{
  background: #fff;
  color: #5C24C9;
  border: 1.5px solid #FFF;
}


/*
nav .link-to-business:hover{
box-shadow: 0 0 0 2px white, 0 0 0 2px white;
border-radius: 25%;
}
 */
.list{
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  gap: 30px;
  margin-right: 75px;
  font-size: 20px;
  font-weight: 500;
}
.mobileMenu{
  display: none;
}
.mobileNav{
  display: none;
}
@media all and (max-width: 700px) {
  .mobileNav{
    display: block;
    background-color: #5C24C9;
  }
  .navbar{
    min-height: 75px;
    background-color: #5C24C9;
    align-items: center;
  }
  .nav{
    display: none;
  }
  .logo{
    margin-left: 50px;
  }
  .logoImage{
    position: relative;
    height: auto;
    width: 99px;
    top: 0;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .mobileMenu{
    margin-top:0px;
    font-size: 24px;
    /* padding-top: 20px; */
    display: inline;
    /* margin-left: 70px; */
  }
  .mobileMenu > * {
    animation-name: rotate;
    animation-duration: 500ms;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .list{
    height: 301px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 19px 0 0 0;
    justify-content: flex-start;
    align-items: flex-end;
    /* left: 0%; */
    margin-top: 0px;
    /* justify-content: left; */
    cursor: pointer;
  }
  .businessLink {
    width: 110px;
    height: 44px;
    padding: 0;
    position: relative;
    left: 22px;
  }
  .list > * { 
    align-self: flex-end;
  }
  /*
  .li{
    font-family: 'Inter';
    text-align: right;
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 1%;
    border-bottom: 10px;
    border-bottom-color: #f4f2f8;
  }
  */
  li.li{
    font-size: 16px;
    font-weight: 500;
  }

  nav .link:hover{
    text-decoration: none;
  }

}
