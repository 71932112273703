.confirmation-container{
    background-color: rgba(0, 0,0 , 0.2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: auto;
    margin: auto;
    padding: 30px;
}
.confirmation{
    background-color: #FAEFF1 ;
    width: 350px;
    /* height: 420px; */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
.close{
    display: flex;
    justify-content: end;
    margin: 10px;
}
.main-content{
    margin: 10%;
    display: flex;
    justify-content: center;
    /* margin: auto; */
}
.sprinkle{
    margin: auto;
}
.sprinkles-2{
    margin-right: 50%;
}
.sprinkle-truck{
    display: flex;
    margin: auto;
    justify-content: end;
    
}
.thank{
    font-family: 'Montserrat';
    color: #5C24C9;
    font-size: 20px;
}
.text{
    width: 80%;
    text-align: center;
}