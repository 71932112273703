.privacy{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color:white ;
    padding-top: 100px;
    margin: 5%;
}
.privacy-container{
    /* margin: 15%; */
    width: 85%;
    background-color: #F7F5FC;
    padding: 50px;
    border-radius: 20px;
    margin-bottom: 100px;
}
.lists{
  list-style-type: disc;
}
@media all and (max-width: 700px) {
    .privacy{
        margin: 10%;
    }
}
