.terms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    align-items: center;
}

.terms-container {
    width: 85%;
    background-color: #F7F5FC;
    padding: 50px;
    border-radius: 20px;
    margin-bottom: 100px;
}

/* 
.lst-kix_y3fx0q99rf55-4>li:before {
    content: "\0025cb   "
}

.lst-kix_y3fx0q99rf55-3>li:before {
    content: "\0025cf   "
}

.lst-kix_y3fx0q99rf55-7>li:before {
    content: "\0025cb   "
}

ul.lst-kix_5hsoo6k53kn3-5 {
    list-style-type: none
}

ul.lst-kix_5hsoo6k53kn3-4 {
    list-style-type: none
}

ul.lst-kix_5hsoo6k53kn3-7 {
    list-style-type: none
}

ul.lst-kix_5hsoo6k53kn3-6 {
    list-style-type: none
}

.lst-kix_y3fx0q99rf55-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_5hsoo6k53kn3-8 {
    list-style-type: none
}

.lst-kix_y3fx0q99rf55-5>li:before {
    content: "\0025a0   "
}

.lst-kix_5hsoo6k53kn3-8>li:before {
    content: "\0025a0   "
}

.lst-kix_5hsoo6k53kn3-7>li:before {
    content: "\0025cb   "
}

.lst-kix_y3fx0q99rf55-0>li:before {
    content: "\0025cf   "
}

.lst-kix_y3fx0q99rf55-1>li:before {
    content: "\0025cb   "
}

.lst-kix_y3fx0q99rf55-2>li:before {
    content: "\0025a0   "
}

.lst-kix_q3hjzsvw17pl-8>li:before {
    content: "\0025a0   "
}

.lst-kix_q3hjzsvw17pl-7>li:before {
    content: "\0025cb   "
}

.lst-kix_q3hjzsvw17pl-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_737na4d3rjcn-3 {
    list-style-type: none
}

.lst-kix_q3hjzsvw17pl-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_737na4d3rjcn-4 {
    list-style-type: none
}

ul.lst-kix_737na4d3rjcn-1 {
    list-style-type: none
}

.lst-kix_q3hjzsvw17pl-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_737na4d3rjcn-2 {
    list-style-type: none
}

ul.lst-kix_737na4d3rjcn-7 {
    list-style-type: none
}

.lst-kix_q3hjzsvw17pl-2>li:before {
    content: "\0025a0   "
}

ul.lst-kix_737na4d3rjcn-8 {
    list-style-type: none
}

ul.lst-kix_737na4d3rjcn-5 {
    list-style-type: none
}

ul.lst-kix_737na4d3rjcn-6 {
    list-style-type: none
}

.lst-kix_q3hjzsvw17pl-4>li:before {
    content: "\0025cb   "
}

.lst-kix_y3fx0q99rf55-8>li:before {
    content: "\0025a0   "
}

.lst-kix_q3hjzsvw17pl-3>li:before {
    content: "\0025cf   "
}

.lst-kix_q3hjzsvw17pl-5>li:before {
    content: "\0025a0   "
}

ul.lst-kix_737na4d3rjcn-0 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-8 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-1 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-0 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-3 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-2 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-5 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-4 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-7 {
    list-style-type: none
}

ul.lst-kix_y3fx0q99rf55-6 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-0 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-5 {
    list-style-type: none
}

.lst-kix_5hsoo6k53kn3-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_q3hjzsvw17pl-6 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-7 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-8 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-1 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-2 {
    list-style-type: none
}

ul.lst-kix_q3hjzsvw17pl-3 {
    list-style-type: none
}

.lst-kix_5hsoo6k53kn3-3>li:before {
    content: "\0025cf   "
}

ul.lst-kix_q3hjzsvw17pl-4 {
    list-style-type: none
}

.lst-kix_5hsoo6k53kn3-4>li:before {
    content: "\0025cb   "
}

.lst-kix_5hsoo6k53kn3-6>li:before {
    content: "\0025cf   "
}

.lst-kix_o675e6ri7qdj-3>li:before {
    content: "\0025cf   "
}

.lst-kix_5hsoo6k53kn3-5>li:before {
    content: "\0025a0   "
}

.lst-kix_o675e6ri7qdj-4>li:before {
    content: "\0025cb   "
}

.lst-kix_o675e6ri7qdj-5>li:before {
    content: "\0025a0   "
}

ul.lst-kix_5hsoo6k53kn3-1 {
    list-style-type: none
}

.lst-kix_o675e6ri7qdj-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_5hsoo6k53kn3-0 {
    list-style-type: none
}

ul.lst-kix_5hsoo6k53kn3-3 {
    list-style-type: none
}

ul.lst-kix_5hsoo6k53kn3-2 {
    list-style-type: none
}

.lst-kix_o675e6ri7qdj-8>li:before {
    content: "\0025a0   "
}

.lst-kix_5hsoo6k53kn3-2>li:before {
    content: "\0025a0   "
}

.lst-kix_o675e6ri7qdj-7>li:before {
    content: "\0025cb   "
}

.lst-kix_5hsoo6k53kn3-1>li:before {
    content: "\0025cb   "
}

.lst-kix_tq1a1yhnrv2c-5>li:before {
    content: "\0025a0   "
}

.lst-kix_tq1a1yhnrv2c-6>li:before {
    content: "\0025cf   "
}

.lst-kix_tq1a1yhnrv2c-4>li:before {
    content: "\0025cb   "
}

.lst-kix_tq1a1yhnrv2c-8>li:before {
    content: "\0025a0   "
}

.lst-kix_tq1a1yhnrv2c-7>li:before {
    content: "\0025cb   "
}

.lst-kix_737na4d3rjcn-1>li:before {
    content: "\0025cb   "
}

.lst-kix_o675e6ri7qdj-2>li:before {
    content: "\0025a0   "
}

.lst-kix_737na4d3rjcn-2>li:before {
    content: "\0025a0   "
}

.lst-kix_o675e6ri7qdj-0>li:before {
    content: "\0025cf   "
}

.lst-kix_o675e6ri7qdj-1>li:before {
    content: "\0025cb   "
}

.lst-kix_737na4d3rjcn-0>li:before {
    content: "\0025cf   "
}

.lst-kix_737na4d3rjcn-7>li:before {
    content: "\0025cb   "
}

.lst-kix_737na4d3rjcn-6>li:before {
    content: "\0025cf   "
}

.lst-kix_737na4d3rjcn-3>li:before {
    content: "\0025cf   "
}

.lst-kix_737na4d3rjcn-5>li:before {
    content: "\0025a0   "
}

.lst-kix_737na4d3rjcn-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_eotrvtddn3ga-8 {
    list-style-type: none
}

ul.lst-kix_eotrvtddn3ga-4 {
    list-style-type: none
}

.lst-kix_tq1a1yhnrv2c-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_eotrvtddn3ga-5 {
    list-style-type: none
}

ul.lst-kix_eotrvtddn3ga-6 {
    list-style-type: none
}

.lst-kix_tq1a1yhnrv2c-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_eotrvtddn3ga-7 {
    list-style-type: none
}

ul.lst-kix_eotrvtddn3ga-0 {
    list-style-type: none
}

ul.lst-kix_eotrvtddn3ga-1 {
    list-style-type: none
}

.lst-kix_tq1a1yhnrv2c-2>li:before {
    content: "\0025a0   "
}

ul.lst-kix_eotrvtddn3ga-2 {
    list-style-type: none
}

ul.lst-kix_eotrvtddn3ga-3 {
    list-style-type: none
}

.lst-kix_tq1a1yhnrv2c-3>li:before {
    content: "\0025cf   "
}

.lst-kix_737na4d3rjcn-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_o675e6ri7qdj-4 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-5 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-6 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-7 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-8 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-0 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-7>li:before {
    content: "\0025cb   "
}

ul.lst-kix_o675e6ri7qdj-1 {
    list-style-type: none
}

ul.lst-kix_o675e6ri7qdj-2 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_o675e6ri7qdj-3 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-5>li:before {
    content: "\0025a0   "
}

ul.lst-kix_tq1a1yhnrv2c-0 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-6>li:before {
    content: "\0025cf   "
}

.lst-kix_eotrvtddn3ga-2>li:before {
    content: "\0025a0   "
}

.lst-kix_eotrvtddn3ga-3>li:before {
    content: "\0025cf   "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_eotrvtddn3ga-4>li:before {
    content: "\0025cb   "
}

ul.lst-kix_tq1a1yhnrv2c-5 {
    list-style-type: none
}

ul.lst-kix_tq1a1yhnrv2c-6 {
    list-style-type: none
}

ul.lst-kix_tq1a1yhnrv2c-7 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_tq1a1yhnrv2c-8 {
    list-style-type: none
}

ul.lst-kix_tq1a1yhnrv2c-1 {
    list-style-type: none
}

ul.lst-kix_tq1a1yhnrv2c-2 {
    list-style-type: none
}

ul.lst-kix_tq1a1yhnrv2c-3 {
    list-style-type: none
}

.lst-kix_eotrvtddn3ga-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_tq1a1yhnrv2c-4 {
    list-style-type: none
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c6 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c0 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c8 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.c4 {
    margin-left: 36pt;
    padding-left: 0pt
}

.c3 {
    padding: 0;
    margin: 0
}

.c5 {
    margin-left: 72pt;
    padding-left: 0pt
}

.c7 {
    margin-left: 108pt;
    padding-left: 0pt
}

.c10 {
    height: 11pt
}

.c2 {
    font-weight: 700
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
} */