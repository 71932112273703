.CTA-Container {
    display: flex; /* This makes it a flex container */
    justify-content: center;
    align-items: center;
    gap: 12px;
    /* max-width: 90%; */
}

.CTA-Container > a {
    flex: 1; /* This makes each button grow to fill the container */
    text-align: center; /* This centers the text within the button */
    text-decoration: none;
}

.btn-nom {
    align-items: center;
    background-color: #5C24C9;
    border-radius: 9px;
    height: 54px;
    justify-content: center;
    overflow: hidden;
    padding: 6.9px 22px;
    position: relative;
    width: 305px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    color: white;
  }

  #nom-one-button {
    text-decoration: none;
  }
  
  .btn-mail{
    align-items: center;
    border: 1.48px solid;
    border-color: black;
    border-radius: 9px;
    /* display: flex; */
    /* gap: 10px; */
    height: 53px;
    justify-content: center;
    overflow: hidden;
    padding: 6.9px 18.98px;
    position: relative;
    width: 230px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
  }

  @media all and (max-width: 700px) {
    .CTA-Container {
        gap: 2em;
        flex-direction: column;
    }
  }