.heroContainer{
  background-image: url('../../../public/About.png');
  background-size:cover;
  background-repeat: none;
  background-position-x: 100%;
  height: calc(100vh - 82px);
  display: flex;
}

.iphone h1 {
  margin-block-end: 1em;
  font-size: 45px;
  font-family: 'Montserrat', sans-serif;
}

.iphone p {
  margin-block-start: 0;
  font-size: 28px;
  font-family: 'Inter', sans-serif;
}

.iphone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin-left: 100px;
}

.mobileHeroContainer,
.mobileAboutImg {
  display:none;
}

.map{
  width:100%;
}

.about{
  font-family: 'Inter';
  top: 0;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.subContainer2{
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  background-color:#DBF3F6 ;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* height: 600px; */
  margin: auto;
  padding: 170px;
}

.subContainer3{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.gift{
  position: relative;
  width: 323px;
  left: 5%;
  top: -6em;
}


.subContainer4{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 150px;
}
.boxes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  align-items: center;
  background-color: rgba(245, 242, 237, 1);
  height: 280px;
  max-width: 387px;
  flex: 0 0 auto;
  border-radius: 15px;
}
.boxes > h3{
  font-size: 24px;
}
.boxes > p{
  font-size: 20px;
}

.boxContainer1 {
  gap: 25px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 70px;
}

.boxContainer2 {
  gap: 25px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
}

.container5{
  background-color: rgba(188, 116, 206, 0.2); /* why is this in rgb? */
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.subContainer5{
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  text-align: center;
  color: #352b44;
  box-sizing: border-box;
  z-index: 0;
  /* background-color:#FAF9F3 ; */
}
.sparkImg{
  margin-top: 77px;
  margin-right: 90%;
  margin-bottom: -40px;
}
.happyGirlImg{
  position: absolute; 
  top: 55%; 
  right: 2%;
  width: 234px; 
}
.container4{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 160px;
  color:#352b44;
}

.joinHeader {
  font-size: 48px;
  margin-block-start: 0;
}

.container4 > * {
  width: 90%;
}

.p1-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.p1-div :nth-child(1) {
  max-width: 600px;
}

.p1-div :nth-child(2) {
  max-width: 712px;
}

.p1 {
  text-align: center;
}

.arrowDiv{
  display: flex;
  justify-content: center;
  position: relative;
}

.arrowDiv img {
  position: relative;
  top: -10px;
  left: -10px;
}

.buttonAndArrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 70px;
}

.downloadArrow{
  margin-top: -20px;
  margin-left: 300px;
}

.btn{
  font-size: 30px;
  font-family: 'Inter';
  margin-top: 50px;
  padding: 15px 30px;
  border-radius: 99px;
}
.btnAboutDownload{
  margin-top: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  z-index: -1;
  background-color: white;
}
.arrows {
  position: relative;
  height: 50px;
  width: auto;
  left: 280px;
  top: -70px;

}
.we{
  color:'#5C24C9';
  font-size:40px;
}
.p1{
  font-size: 24px;
}
.h4{
  font-size: 30px;
}
.p2{
  max-width: 80%;
  color: #352B44;
  font-size: 35px;
}
.h2{
  max-width: 90%;
  color: #352B44;
  font-size: 40px;
}
@media all and (max-width: 1200px) { 
  .happyGirlImg{
    position: absolute; 
    left: 3%;
    top: auto;
    bottom: -7%;
    width: 161px;
  }
}
@media all and (max-width: 1000px) {
  .heroContainer{
    display: none;
  }
  .iphone {
    width: 100%;
    margin: 0;
  }
  .mobileHeroContainer{
    margin: auto;
    margin-top: 5em;
    display:block;
    text-align: center;
    align-items: center;
    max-width: 90%;
  }
  .mobileAboutImg {
    display: block;
    width: 100%;
    margin-bottom: -10px;
  }
  .mobileAboutImg img {
    width: 100%;
    height: auto;
  }
}
@media all and (max-width: 700px) {
  .iphone > h4 {
    font-family: "Montserrat";
    line-height: 36px;
    font-weight: 700;
    align-self: center;
    font-size: 36px;
    margin: 0 -1px 1em -1px;
  }

  h4 > span {
    display: block;
  }

  .iphone > p{
    font-family: "Inter";
    font-size: 16px;
    margin-top: 0;
  }
  .boxContainer{
    margin: 10%;

  }
  .subContainer2{
    padding: 20% 2% 40% 2%;
    margin-top: inherit;
  }

  .gift{
    position: absolute;
    width: 179px;
  }

  .subContainer35{
    margin-top: 100px;
  }
  .boxes > h3{
    font-size: 18px;
  }
  .boxes > p{
    font-size: 15px;
  }
  .boxes {
    width: 90%;
    max-width: 400px;
    padding: 0;
  }
  .boxes h3,
  .boxes p {
    width: 90%;
  }
  .subContainer5{
    max-width: 90%;
    padding-bottom: 5em;
  }
  .spark1{
    position: relative;
    top: 0;
    right: 35px;
    width: 35px;
    height: 45px;
  }
  .spark2{
    position: relative;
    top: 20px;
    right: 0;
    width: 47px;
    height: 48px;
  }
  .we{
    font-size: 28px;
    color:'#5C24C9';
    margin-left: 10px;
  }
  .p1{
    font-size: 16px;
  }

  .p1-div :nth-child(1) {
    max-width: 324px;
  }

  .p1-div :nth-child(2) {
    max-width: 394px;
  }

  .h4{
    font-size: 22px;
  }
  .p2{
    font-size: 20px;
  }
  .h2{
    font-size: 22px;
    font-weight: 600;
  }
  .container-4{
    margin: 10%;
  }
  .arrows {
    height: 35px;
    width: auto;
    transform: rotate(25deg);
    top: 40px;
    left: 50px;
  }
  .joinHeader {
    font-size: 28px;
    margin-block-start: 0;
  }
  .joinHeader span {
    position: relative;
  }
  .arrowDiv {
    position: relative;
  }
  .arrowDiv img {
    position: relative;
    top: -25px;
    left: -15px;
  }
  /*
  .arrows{
  margin-top:-40px;
  }
   */
.btn{
  padding: 5px 20px;
}
}

@media all and (max-width: 440px) { 
  .spark1 {
    right: 15px;
  }
  .iphone > h4,
  .iphone > span {
    font-size: 30px;
  }
  .arrowDiv {
    margin-top: 20px;
  }
  .joinHeader {
    font-size: 22px;
  }
}
