/*
.footer{
    font-family: 'Montserrat Alternates';
    padding-bottom: 50px;
    margin-top: 100px;
    background-color:#352B44;
    color: white;
    width: 100%;

}
*/
.footerCurve > img {
  width: 100%;
  position: relative;
  top: 20px;
  margin-top: 50px;
}
.footerContainer{
    font-family: 'Montserrat Alternates';
    background-color:#352B44;
    color: white;
    max-width: 100%;
    padding-top: 50px;
    padding-left: 100px;    
    padding-right: 100px;    
    padding: 50px 0 20px 100px;
}
.infoSection{
    display: flex;
    gap: 50px;
}
.copyright{
    font-family: 'Inter';
    margin-top: 50px;
}
.iconsContainer{
    display: flex;
    gap: 10px;
}
.link{
    font-family: 'Inter';
    color: white;
    text-decoration: none;
    font-size: 16px;
}
.link:hover{
    /* text-decoration: overline; */
    color: #a09bab;
    font-family: 'Inter';
    
}
.a{
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 16px;
}
.a:hover{
    color: #a09bab;
}
@media all and (max-width: 700px) {
    .infoSection{
        flex-wrap: wrap;
        justify-content: start;
    }
    .footerContainer{
        padding-left: 20px;
        padding-right: 20px;
    }
    .logoImg{
      display: flex;
      justify-content: flex-start;
      padding-bottom: 28px;
    }
    .logoImg > img{
      width: 164px;
      height: auto;
    }
    hr {
      display: none;
    }
    h2 {
      font-family: 'Inter';
      font-size: 16px;
    }
    p {
      font-family: 'Inter';
      font-size: 14px;
    }
}

@media all and (max-width: 580px) {
    .legal{
      padding-left: 15vw;
    }
}
