.business{
   
    font-family: ' Inter';
    max-width: 100%;
    margin-top: 2em;
}

h1 {
  padding-bottom: 1em;
}

.business-container{
    z-index: -1;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.business-frame-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 20px;
}
.community{
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  align-items: flex-start;
  margin-left: 70px;
}

.community > p {
  padding-bottom: 1em;
}

.dual-iphone-wrapper {
  margin-left: 2em;
}

.dual-iphone {
  width: 100%; /* Ensures the image scales with the width of its container */
  height: auto; /* Adjust height automatically to maintain aspect ratio */
  object-fit: contain; /* Ensures the whole image is always visible, may add letterboxing */
  max-height: none; /* Remove max height if it's causing cutoff */
}

.business-CTA-Container {
  display: flex; /* This makes it a flex container */
  justify-content: center; /* This will put space between your buttons */
  align-items: center;
  gap: 20px;
}

.business-CTA-Container > a {
  flex: 1; /* This makes each button grow to fill the container */
  text-align: center; /* This centers the text within the button */
  margin: 0 10px; /* This ensures there's a little space between the buttons */
  text-decoration: none;
}

/* Style for the buttons themselves, in case you need to adjust padding or other properties */
.business-CTA-Container > a > button {
  padding: 10px 20px; /* Example padding, adjust as necessary */
}

.btn-nom {
  align-items: center;
  background-color: #5C24C9;
  border-radius: 9px;
  height: 54px;
  justify-content: center;
  overflow: hidden;
  padding: 6.9px 22px;
  position: relative;
  width: 290px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.business-frame-2{
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 170px;
    margin-right: 170px;
}

/* This will make sure the .quote-wrapper works as a flex container */
.quote-wrapper {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
}

.left-large-quote {
  height: 100px;
  padding-left: 77px;
  justify-content: flex-start;
  padding-top: 2em;
}

.right-large-quote {
  height: 100px;
  justify-content: flex-end;
  margin-left: auto; /*This pushes the item to the right by automatically adjusting the left margin*/
  transform: scaleX(-1) scaleY(-1);
  padding-right: 77px;
}

.sub-header{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.img{
    height:21px ;
    width: 21px;
}

.business-frame-sub-frame-2 {
  display: flex; /* Enables flexbox layout */
  flex-direction: column;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  height: 100%; /* Ensures the container takes up the full height */
  text-align: center; /* Centers the text inside the container */
  padding-left: 95px;
  padding-right: 95px;
}

#easy {
  color: #5C24C9;
  position: relative;
}

#circle {
  position: absolute;
  top: 134.5%; /* Adjust as needed */
  left: 33.5%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the SVG */
  width: 130px; /* Adjust as needed */
  height: auto;
  /* Additional styling for the SVG */
}

.coffee-cafe {
  position: relative;
  width: 500px;
  padding-top: 20%;
  padding-bottom: 20%;
}
.sentece{
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
}
.sub-header + .sentece {
  margin-top: -5px;
}
h1 + p.sentece {
  padding-left: 0;
}
.business-frame-2 h1 {
  text-align: center;
}
.h{
  font-size: 22px;
}

.business-frame-3{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #F6FDFD;
  width: 100%;
}

h2.h1-b {
  font-size: 38px;
  margin-top: -14px;
  font-weight: 700;
  color: #5C24C9;
}

h2.h1-b + h1.h1-b {
  font-size: 38px;
  margin-top: -14px;
  font-weight: 700;
}

.sub-business-frame-3{
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* align-items:flex-start; */
  max-width: 90%;
}

.loyal-customers {
  height: 44px;
  color: #5c24c9;
  font-size: 36px;
  font-weight: 700;
  /* max-width: 625px; */
}

.loyal-customers-sub {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  height: 140px;
  max-width: 615px;
}

.nombolo-makes {
  font-size: 36px;
  font-weight: 600;
  max-width: 625px;
  height: 88px;
}

.customers-know {
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  max-width: 643px;
  height: 105px;
}

.digital-list-item {
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  max-width: 615px;
}

.toggle-switch {
  margin: 1em;
  position: relative;
  display: inline-block;
  width: 200px; /* Width of the switch */
  height: 50px; /* Height of the switch */
}

.toggle-switch input { 
  opacity: 0; /* Hide the default checkbox */
  width: 0; 
  height: 0; 
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Off state color */
  transition: .4s;
  border-radius: 40px; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 50px; /* Height of the slider */
  width: 100px; /* Width of the slider */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 36px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(100px); /* Slider move distance */
}

.toggle-label {
  display: inline-block;
}

.slider:before {
  position: absolute;
  content: "";
  height: 50px; /* Height of the slider */
  width: 100px; /* Width of the slider */
  left: 0;
  top: 0; /* Adjusted for vertical centering */
  bottom: 0; /* Adjusted for vertical centering */
  background-color: white;
  transition: .4s;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: black;
}

.join-button {
  text-decoration: none;
}

/* Text for the OFF state */
input:not(:checked) + .slider:before {
  content: "Bi-annual Price";
}

/* Text for the ON state */
input:checked + .slider:before {
  content: "Monthly Price";
}


@keyframes slideshow {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }

}

.reward-img-wrapper img,
.check > * {
  animation-duration: 2s;
  animation-name: slideshow;
}

.business-frame-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding-bottom: 5em; */
  /* padding-top: 5em; */
}

.loyal-customers {
  /* existing styles */
  margin-bottom: 30px; /* Add this line to set the bottom margin to 30px */
}

/* Optionally, if there's top margin on the following text, you may need to adjust or remove it */
.loyal-customers-sub {
  /* existing styles */
  margin-top: 0; /* Set top margin to 0 or a small value as needed */
}

.why-digital {
  width: auto; /* Or a specific width, like 100% */
  padding-right: 40px; /* Adjust padding as needed */
}

.stamp-img {
  width: 430px;
}

.stamp-card-img-wrapper {
  padding: 65px;
}

.business-frame-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 90%; */
  background-color: #f7f5fc;
  border-radius: 30px;
  padding: 47px;
  margin-bottom: 2em;
}

.nom-crown {
  width: 4em;
}

.you-can {
  font-size: 40px;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: center;
}

.you-can-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.business-feature {
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally centers the content */
  justify-content: center; /* Vertically centers the content */
  text-align: center; /* Centers the text inside each child element */
  padding: 2%;
  margin: 1%;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 400px;
}

.business-feature > p {
  font-size: 14px;
  /* Additional styling if needed */
}


.business-feature > p {
  font-size: 14px;
}

.business-feature-img {
  height: 130px;
}

.business-prices-frame {
  display: flex;
  flex-direction: column;
  width: 97%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 27px;
  margin-top: 100px;
  align-items: center;
}

#highlight {
  background-color: #eebe2c;
  border-radius: 6px;
  font-weight: 600;
  position: absolute;
  display: flex; /* Set display to flex */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  width: 170px;
  top: -10px; /* Adjust as needed */
  right: -10px; /* Adjust as needed */
  padding: 5px 10px; /* Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
  text-align: center; /* Center text alignment */
}


.pricing-options {
  display: inline-flex;
  align-items: flex-end;
  max-width: 100%;
  justify-content: space-around;
  padding-top: 3em;
}

.price-free {
  flex: 1;
  border: 1px solid;
  border-color: #000000;
  border-radius: 15px;
  padding: 2%;
  margin: 2%;
  width: 415px;
  height: 360px;
  align-items:baseline;
}

.price-nom-one {
  position: relative;
  flex: 1;
  background-color: #5c24c91a;
  border-radius: 15px;
  padding: 2%;
  margin: 2%;
  height: 415px;
  width: 415px;
  align-items:baseline;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

#nom-one-price-title {
  color: #5C24C9;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: -.5em;
}

#nom-one-price {
  color: #5C24C9;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: -.5em;
}

#save {
  color: #979797;
  font-size: 27px;
}

#free {
  font-weight: 700;
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 18px;
}

#free-price {
  font-weight: 500;
  font-size: 32px;
}

.price-nom-one > .btn-nom {
  width: 100px;
}

.price-free, .price-nom-one {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.btn-price-toggle{
  align-items: center;
  border: 1.48px solid;
  border-color: black;
  border-radius: 15.33px;
  display: flex;
  /* gap: 10px; */
  height: 53px;
  justify-content: center;
  overflow: hidden;
  /* padding: 6.9px 18.98px; */
  position: relative;
  font-family: 'Inter';
  /* font-weight: 600; */
  font-size: 1.5em;
}

#nom-one-membership {
  font-size: 40px;
}

#download-info {
  padding-bottom: 2em;
  margin-top: -10px;
  font-weight: 500;
  font-size: 24px;
}

#membership-info {
  font-size: 20px;
  text-align: center;
  max-width: 750px;
  padding-bottom: 1em;
  font-weight: 500;
  /* margin-top: -10px; */
  /* margin-top: 0px; */
}

.nomone-item-features {
  list-style: none; /* Removes default bullets */
  padding-left: 0; /* Optional: Removes default padding */
}

.nomone-item-features li {
  margin-bottom: 10px; /* Adjust the space between list items */
}

.nomone-item-features li:last-child {
  margin-bottom: 0; /* Remove margin from the last item to maintain button position */
}

.nomone-item-features li::before {
  background-image: url('/public/Listitem2.png'); /* Path to your image */
  display: inline-block; /* Allows width and height to be set */
  width: 15px; /* Adjust width as needed */
  height: 15px; /* Adjust height as needed */
  background-size: cover; /* Ensures the image covers the pseudo-element */
  margin-right: 8px; /* Adjust space between the icon and text */
  content: ''; /* Keep this to ensure the pseudo-element is generated */
}

.feature-unavailable {
  list-style: none;
  padding-left: 0;
  font-size: 15px;
}

/* Style for the first list item */
.feature-unavailable li:first-child::before {
  background-image: url('/public/Listitem2.png'); /* Path to your image */
  display: inline-block; /* Allows width and height to be set */
  width: 15px; /* Adjust width as needed */
  height: 15px; /* Adjust height as needed */
  background-size: cover; /* Ensures the image covers the pseudo-element */
  margin-right: 8px; /* Adjust space between the icon and text */
  content: ''; /* Keep this to ensure the pseudo-element is generated */
}

/* Style for the rest of the list items */
.feature-unavailable li:not(:first-child)::before {
  background-image: url('/public/ListItem.png'); /* Path to your image */
  display: inline-block; /* Allows width and height to be set */
  width: 15px; /* Adjust width as needed */
  height: 15px; /* Adjust height as needed */
  background-size: cover; /* Ensures the image covers the pseudo-element */
  margin-right: 8px; /* Adjust space between the icon and text */
  content: ''; /* Keep this to ensure the pseudo-element is generated */
}


.feature-unavailable li {
  margin-bottom: 10px; /* Adjust the space between list items */
}

.feature-unavailable li:last-child {
  margin-bottom: 0; /* Remove margin from the last item to maintain button position */
}

.price-nom-one p {
  padding-top: 5px; /* Add 5px padding to the top */
  /* Other existing styles */
}

#save {
  font-weight: 500;
}

.check{
  flex: 0 0 383px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: start;
  margin-bottom: 10%;
}

.check p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  margin-block-start: .5rem;
}

.checkDiv {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.business-signup{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f6fdfd;
  gap: 2em;
}

.signupForm {
  display: flex;
  align-items: center; /* To vertically align the items if they have different heights */
  justify-content: space-evenly; /* Optional: to put maximum space between the two items */
  margin: 30px;
}

.svgWrapper img {
  width: 100%;
  height: auto;
  padding: 2em;
  max-height: 500px;
}

.form-text {
  width: 100%;
  margin: 50px 0 40px 0;
}

.form-text > * {
  text-align: left;
  margin: 25px 0px;
}

.form-header-test,
.form-header-small {
  font-family: 'montserrat';
  font-size: 40px;
  font-weight: 700;
  color: rgba(92, 36, 201, 1);
  margin-bottom: 70px;
}

.form-header,
.form-header-small {
  font-family: 'montserrat';
  align-self: flex-start;
  font-size: 2.5em;
  font-weight: 700;
  color: rgba(92, 36, 201, 1);
}

.form-header-small {
  font-size: 2em;
}

.form-p {
  font-family: 'Inter', sans-serif;
  font-size: 1.4em;
  font-weight: 600;
  line-height: 30px;
}

.orange-curly-arrow {
  position: relative;
  left: 50px;
  top: 50px;
}

.arrow-div{
    display: flex;

}
.btn-div{
  display: flex;
  justify-content:space-between;
  margin-top: 200px;
  gap:  90px;
}

.h1{
  font-size: 45px;
  text-align: start; 
  padding-top: 2em;
}

.h1-b {
  font-family: 'montserrat';
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #ea6656;
}


.p1-b{
  font-size:30px;
}

.p2-b{
  font-size:16px;
}

@media all and (max-width: 1300px) {

  .business-frame-1 {
    flex-direction: column;
    align-items: center;
    margin-top: 82px;
  }

  .community {
    width: 80%;
    align-items: center;
  }

  .business-frame-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .business-frame-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .pricing-options {
    flex-direction: column;
    align-items: center;
  }

  .why-digital > p {
    text-align: center;
  }
  
  .dual-iphone-wrapper {
    width: 100%; /* Use 100% of the container width */
    justify-content: center; /* Center the image horizontally */
  }

  .business-CTA-Container {
    flex-direction: column;
    width: 100%;
  }

  /* Additional styles for buttons to make them full width on smaller screens, if desired */
  .business-CTA-Container > a,
  .business-CTA-Container > a > button {
    width: 100%;
    margin: 0; /* Resetting margins might be necessary depending on your design */
  }

  /* You might want to add some space between your buttons when they stack */
  .business-CTA-Container > a:not(:last-child) {
    margin-bottom: 20px; /* Adjust as necessary */
  }

  .business-signup {
    display: block;
    width: 100%;
    flex-direction: column;
    align-content: center;
    display: flex;
    gap: 0;
    padding-bottom: -40px;
  }

  .signupForm {
    width: 90%;
    display: block;
    margin: 30px auto;
  }

  .svgWrapper {
    width: 100%;
    display: flex;
  }
  
  .svgWrapper img {
    width: 75%;
    height: auto;
    margin-left: 75%;
  }

  .form-text > * {
    text-align: center;
  }

  #membership-info {
    max-width: 75%;
  }

  .pricing-options {
    flex-direction: row;
  }
}

@media all and (max-width: 700px) {
    .business{
        margin-top: 80px;
    }
    .business-container {
      gap: 10px;
    }
    .business-frame-sub-frame-2{
        width: 95%;
    }
    .business-frame-1{
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        gap: 20px;
        margin: 10%;
    }

    .business-CTA-Container {
      flex-direction: column;
    }

    .customers-know {
      padding-bottom: 1em;
    }

    .btn-nom {
      margin-bottom: 20px;
    }

    .community{
        text-align: center;
    }
    .h1-b{
        font-size: 28px;
        text-align: center;
    }
    /* .business-frame-2 {
      margin-left: 0;
    } */
    .business-frame-2 h1,
    .business-frame-2 p {
      text-align: center;
    }
    .h1{
        font-size: 28px;
        text-align: center;
    }
    .p1-b{
        font-size: 20px;
        text-align: center;
    }
    .btn-b{
        align-self: center;
        font-size: 14px;
        width: 150px;
        height: 35px;
    }
    .p2-b{
        font-size: 12px;
    }
    .business-frame-2{
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
    }

    .form-header {
      font-size: 2em;
    }

    .form-p {
      font-size: 1em;
    }

    .svgWrapper img,
    .orange-curly-arrow {
      position: relative;
      padding: 0;
      top: 0;
      left: 0;
    }

    .orange-curly-arrow {
      transform: scale(.7, .7);
    }

    .coffee-cafe,
    .reward-img-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .coffee-cafe img {
      width: 90%;
    }
    .reward-img-wrapper img {
      width: 80%;
      height: auto;
      padding: 10%;
    }

    #membership-info {
      max-width: 90%;
    }

    .check{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: start;
      margin: 0;
      width: 70%;
    }
    
    .check p {
      justify-self: flex-start;
    }

    .business-signup{
      flex-direction: column;
      justify-content: center;
    }

    .business-frame-5 {
      width: 95%;
    }

    .you-can-list {
      align-items: center;
      flex-direction: column;
    }

    .pricing-options {
      flex-direction: column;
    }

    .loyal-customers {
      padding-bottom: 1em;
    }
}
