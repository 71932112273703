
.contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 170px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align:center;
  gap: 5px;
  color: #352B44;
}
.h1-contact{
  color:#352B44;
  font-family: 'Montserrat';
  font-size: 45px;
  font-weight: 700;
}
.p-contact{
  color:#352B44;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 500;
}
.contact-container{
  width: 90%;
  margin-top: 75px;
  margin-bottom: 135px;
  background-color:#F7F5FC ;
  border-radius: 30px;
  position: relative;

  /* padding: 50px; */
}
.form-container{
  display: flex;
  flex-direction: column;
  gap: 51px;
  width: 55%;
  margin: 100px auto;
}
.name{
  display: flex;
  justify-content: space-between;


}
.column{
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
  gap: 16px;
  width: 45%;
}
.column label,
.message label{
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
}
.select{
  justify-content: center;
  text-align: start;
}
.select p {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
}

/* RADIO BUTTON STUFF */
.radio {
  display: flex;
  justify-content: space-between;
}

.input-radio {
  display: flex;
  gap: 7px;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #E0E0E0;
  position: relative;
  top: -2px;
}

input[type="radio"]:checked {
  background-color: #5C24C9;
  top: -4px;
  width: 24px;
  height: 24px;
}

input[type="radio"]:checked:after {
  position: absolute;
  content: '';
  top: 6px;
  left: 9px;
  width: 4px;
  height: 7px;
  transform: rotate(30deg);
  border-right: white 2px solid;
  border-bottom: white 2px solid;
}


textarea{
  box-sizing: border-box;
  border-radius: 10px;
  height: 122px;  
  width: auto;
  font-family: 'Inter';
  font-size: 18px;
  padding: 20px;
}
.input-field{
  border: none;
  background-color: transparent;
  border-bottom:  solid #352B44 1px;
  font-size: 18px;

}
.input-field:focus{
  outline: none;
}
.message{
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
  text-align: start;
}
.send-message{
  display: flex;
  justify-content: end;
  margin-bottom: 100px;
}
.fist-bump > img{
  position: absolute;
  top: auto;
  bottom: -15%;
  left: 0;
  width: 253px;
  height: auto;
}

.input-field[type="radio"] {
  display: none;
}

.input-field[type="radio"] + label {
  display: inline-block;
  background: url('../../../public/unselected-1.png') no-repeat;
  width: 24px;
  height: 24px;
  padding-left: 28px;
  cursor: pointer;
}

.input-field[type="radio"]:checked + label {
  background: url('../../../public/selected-1.png') no-repeat;
}

.btn{
  width: 250px;
  height: 60px;
  padding: 10px 22px 10px 22px;
  border-radius: 10px;
  gap: 10px;
  background-color: #5C24C9;
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.thank-you-note {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(92, 36, 201, 1);
  justify-self: center;
  align-self: center;
  margin-bottom: 70px;
}

.hidden {
  display: none;
}




@media all and (max-width: 980px) {
  .contact-container{
    border-radius: 30px;
    justify-content: center;
    width: 85%;
  }
  .h1-contact{
    font-size: 36px;
  }
  .p-contact{
    width: 70%;
    font-size: 18px;
    line-height: 21px;
  }
  .form-container {
    width: 74%;
    gap: 30px;
  }
  .name{
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
  .select p {
    margin-bottom: 25px;
  }
  .column label,
  .message label,
  .select p {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
  }
  .input-radio{
    font-size: 14px;
  }
  .radio{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  textarea {
    font-size: 16px;
  }
  .column{
    width: 100%;
    gap: 5px;
  }
  .send-message{
    justify-content: center;
    margin-bottom: 0;
  }
  .btn{
    width: 150px;
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 90px;
  }
}

/* .contact{
display: flex;
flex-direction: column;
padding-top: 100px;
align-items: center;
text-align:center;
}
.contact-container{
display: flex;
justify-content: center;
background-color:#F7F5FC ;
width: 85%;

}

.form-container{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align:start;
padding: 50px;
gap: 50px;

}

.btn{
  /* width:250px;
  height: 60px; */
/* background-color: #5C24C9;
border-radius: 999px;
border: none;
color: white;
font-size: 20px;
font-weight: 600;
margin-bottom: 100px;
cursor: pointer;
}
.input-container{
display: flex;
justify-content: center;
gap: 200px;
}
.inputs{
display: flex;
gap: 30px;
flex-direction: column;
}
input{
border: none;
border-bottom: 2px solid black;
width: 100%;
background-color: transparent;

}
.radio{
display: flex;
gap: 10%;
justify-content: start;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 15px;
}
.select{
display: flex;
text-align: start;
justify-content: start;
flex-direction: column;
} */ 
