.waitlist{
    position: fixed;
    width: 100%;
    height: 100vh;
    /* margin-top: 100px; */
    /* background-color: rgba(0, 0,0 , 0.2); */
    /* padding: 20px;   */
}
.waitlist-container{
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: auto;
    background-color:  #FAEFF1;
    border-radius: 20px;

}
.header-waitlist{
    text-align: center;
    margin: 10%;
}
.icon-container{
    margin:20px;
    display: flex;
    justify-content: space-between;
    

}

.form{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    padding-left: 40px;
}
.waitlist-btn{
    justify-content: center;
    align-content: center;
    align-self: center;
    
    /* border-radius: 999px; */
  
}
.btn{
    background-color: #5C24C9;
    border: none;
    color: #fff;
    padding:10px 20px;
    font-size: 18px;
}
.inputs{
    font-size: 18px;
    /* border: none; */
    background-color: transparent;
}
.inputs:focus{
    outline: none;
}
.happy{
    display: flex;
    justify-content: end;
    
}
.asterics{
    color: red;
}


@media all and (max-width: 700px) {
    .waitlist-container{
        width: 80%;
    }
    .form{
        gap: 10px;
    }

}