.form-container{
  display: flex;
  flex-direction: column;
  gap: 51px;
  width: 55%;
  margin: auto;
  margin-top: 100px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

form > * {
  width: 95%;
}

.name{
  display: flex;
  justify-content: space-between;
}

.column{
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
  gap: 16px;
  width: 45%;
}

.column label,
.message label{
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
}

.select{
  justify-content: center;
  text-align: start;
}

.select p {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
}

/* RADIO BUTTON STUFF */
.radio {
  display: flex;
  justify-content: space-between;
}

.input-radio {
  display: flex;
  gap: 7px;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #E0E0E0;
  position: relative;
  top: -2px;
}

input[type="radio"]:checked {
  background-color: #5C24C9;
  top: -4px;
  width: 24px;
  height: 24px;
}

input[type="radio"]:checked:after {
  position: absolute;
  content: '';
  top: 6px;
  left: 9px;
  width: 4px;
  height: 7px;
  transform: rotate(30deg);
  border-right: white 2px solid;
  border-bottom: white 2px solid;
}


textarea{
  box-sizing: border-box;
  border-radius: 10px;
  height: 122px;  
  width: auto;
  font-family: 'Inter';
  font-size: 18px;
  padding: 20px;
}
.input-field{
  border: none;
  background-color: transparent;
  border-bottom:  solid #352B44 1px;

}
.input-field:focus{
  outline: none;
}
.message{
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 20px;
  text-align: start;
}
label {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.fist-bump{
  position: absolute;
  top: auto;
  bottom: -15%;
  left: 0;
}
.fist-bump > img {
  width: 253px;
  height: auto;
}
.input-field[type="radio"] {
  display: none;
}

.input-field[type="radio"] + label {
  display: inline-block;
  background: url('../../../public/unselected-1.png') no-repeat;
  width: 24px;
  height: 24px;
  padding-left: 28px;
  cursor: pointer;
}

.input-field[type="radio"]:checked + label {
  background: url('../../../public/selected-1.png') no-repeat;
}

.btn{
  width: 157px;
  height: 52px;
  background-color: #5C24C9;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 70px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.send-message {
  justify-self: flex-start;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

@media all and (max-width: 1200px) {
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
}

@media all and (max-width: 700px) {
  .name {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .column {
    width: 90%;
  }

  form {
    gap: 2em;
  }
  
  .btn {
    margin-top: 2em;
    width: 88px;
    height: 43px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
  }
}
