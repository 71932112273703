#root {
  overflow: hidden;
}

.hidden {
  display: none;
}

.hero{
  /* font-family: 'Montserrat Alternates'; */
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  width: 100%;
}
.fade-in {
  /* opacity: 0; */
  animation-name: slide-in 5s;
  /* animation-duration: 5s; */
  /* animation-fill-mode: forwards; */
}

@keyframes slide-in {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}


.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  margin-top: 100px;
  /* text-align: center; */
  justify-content: center;
  font-family: 'Inter';

}

.test {
  /* background-color: #F5F2ED; */
  background-color: rgba(188, 116, 206, 0.1);
  padding: 65px 65px;
  border-radius: 30px;
  width: 85%;
}

.nombolo{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  color: #5C24C9;
  margin-top: 192px;
  margin-bottom: 30px;
}

.sentence{

  width: 783px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  align-items: center;
  text-align: center;
  letter-spacing: 1%;

  color: #352B44;

}

.sentence2{

  /* width: 783px; */
  width: 980px;
  height: 138px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 36px;
  align-items: center;
  text-align: center;
  letter-spacing: 1%;
  color: #352B44;
}

span{
  font-weight: bold;
}
.link-to-app{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  background-color: #5C24C9;
  border-radius: 13.91px;
  border: none;
  color: white;
  padding: 8.75px;
  gap: 15px;
  cursor: pointer;
}

#right {
  margin: 10px;
  gap: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-store-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}

#androidButton,
#appleButton {
  padding: 0;
}

#app-btn-top {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

#app-btn-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-right: 3px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

img{
  border: none;
}

#left {
  height: auto;
  width: 248px;
  border-radius: 9px;
}

#apple {
  height: 17.5px;
}

button {
  border: none;
  background-color: inherit;
  display: inherit;
}

.connect-share-reward-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-header2{
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 30px;
  align-items: center;
  text-align: center;
  color: #5C24C9;

}

.connectContainer{
  font-family: 'Montserrat Alternates';
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* gap: 100px; */
  /* margin: auto; */
}

.connectContainer-2{
  font-family: 'Montserrat Alternates';
  display:flex;
  justify-content: space-between;
  gap: 90px;
  width: 85%;
  margin: auto;
}
.connectContainer1{
  max-width: 80%;
}
.subContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  max-width: 510px;
}
.connect{
  max-width: 80%;
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 49px;
}

h1.connect {
  font-size: 40px;
  align-self: flex-start;
}

.hero-image {
  width: 474.18px;
  /* height: 553.88px; */
}

.image-offset {
  position: relative;
  left: -49px;
}

.hero-image-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 670px;
  margin-bottom: 90px;
}

.p{
  font-family: 'Inter';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 24px;
  color: #352B44;
  margin-top: -20px;
  margin-bottom: .75em;
}

.connect-share-reward{
  margin: 30px;
  margin-top: 100px;
}

.shareContainer{
  max-width: 80%;
  margin: auto;
  align-items: center;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
}

.feedback {
  margin: 100px
}

.feedback-h2 h2 {
  font-size: 40px;
  font-family: "Montserrat Alternates";
  font-weight: 700;
  margin-bottom: 100px;
  text-align: center;
}

.quotes{
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  gap: 3%;
}
.quotes-container{
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 20% 16% 52% 13%;
  background-color:#F5F2ED ;
  width: 387px;
  height: 378px;
  padding: 20px;
  border-radius: 12px;
}
.quotes-container :nth-child(1) {
  align-self: center;
  grid-row-start: 1;
  grid-row-end: 2;
}
.quotes-container :nth-child(2) {
  align-self: center;
  grid-row-start: 2;
  grid-row-end: 3;
}
.quotes-container :nth-child(3) {
  grid-row-start: 3;
  grid-row-end: 4;
}
.quotes-container :nth-child(4) {
  margin-top: 3px;
  grid-row-start: 4;
  grid-row-end: 5;
}

.quotes-container > p {
  font-weight: 400;
  font-size: 20px;
}

.reward-Container-1{
  margin-top: 100px;
  display: flex;
  /* max-width: 0%; */
}

.for-business-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  height: 100%;
  color: white;
  margin-left: 10%;
}
.for-business-container h2{
  text-align: start;
  font-size: 40px;
  font-weight: 700;
  font-family: 'Montserrat';
}
.for-business-container p{
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
}

.businesss{
  background-image: 
    linear-gradient(161.04deg, rgba(0, 0, 0, 0.2) 70.86%, rgba(0, 0, 0, 0) 66.5%),
    linear-gradient(51.54deg, rgba(0, 0, 0, 0.2) 95.42%, rgba(0, 0, 0, 0) 79.01%), url('../../../public/smallbusiness.png');
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;

}

.business-button {
  margin-top: 50px;
  background-color:#5C24C9;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  z-index: -1;
  width: 250px;
  height: 66px;
}

.arrow{
  margin-top: -90px;
  margin-left: 60%;
}

.download-link{
  font-family: 'Inter';
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-link-paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 712px;
}

.download-link-paragraph > p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.arrow-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.three-arrows {
  width: 47.7px;
  height: 44.2px;
  position: relative;
  top: -28px;
}

.curly-arrow {
  position: relative;
  left: calc(235px + calc(237px / 2));
  top: calc(-65px / 2);

}

.p1{
  font-size: 24px;
}

.mobile-image{
  display: none;
}


/* MEDIA QUERIES HERE */

@media (max-width: 1200px) {
  .sentence2{
    max-width: 783px;
    width: 85%;
    font-size: 26px;
    line-height: 36px;
  }

  .connectContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .connectContainer.share {
    flex-direction: column;
  }
  .subContainer {
    text-align: center;
  }
  h1.connect {
    align-self: center;
  }

  .hiddenEl {
    display: none;
  }

  @keyframes slideshow {
    0% {
      opacity: 40%;
    }
    100% {
      opacity: 100%;
    }
  }

  .quotes-container {
    animation: slideshow 1s;
  }

  .dot {
    background-color: #979797;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .dot.selected {
    background-color: rgba(234, 102, 86, 1);
  }

  .dots {
    position: relative;
    display: flex;
    gap: 15px;
    top: 3em;
    left: 40%;
  }
}

@media (max-width: 1100px) {
  .curly-arrow {
    position: relative;
    transform: rotate(25deg);
    top: calc(-38px + 90px);
    left: 50px;
  }

  .hidden {
    display: block;
  }

  .mobile-image{
    display: block;
    /* display: flex;
    flex-direction: column;
    align-self: center; */
    margin: auto;
    /* justify-content: center; */
    align-items: center;
    width:200px;
  }

  .hero-image{
    display: none;
    align-self: center;
    align-items: center;
    justify-content: center;
    width:240px;
  }

  .hero-image-1{
    align-self: center;
    align-items: center;
    justify-content: center;
    width:309px;
    margin-top: 90px;
    margin-bottom: 60px;
  }


}

@media (max-width: 800px) {
  .sentence{
    margin: auto;
    font-size: 24px;
    width: 80%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .sentence2{
    width: 85%;
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 40px;
  }

  .download-link {
    margin-top: 100px;
  }

  .feedback-h2 > h2 {
    font-size: 28px;
  }

}

@media all and (max-width: 700px) {
  .nombolo {
    margin-top: 87px;
    margin-bottom: 30px;
    font-size: 48px;
  }
  
  .sentence {
    width: 300px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 1%;

    color: #352B44;

  }

  .header{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .test {
    display: flex;
    flex-direction: column;
    justify-self: center;
    /* background-color:#F7F5FC ; */
    border-radius: 30px;
    padding: 0;
  }

  .sentence2{
    width: 80%;
    font-size: 16px;
    line-height: 21px;
  }

  .connectContainer {
    gap: 20px;
    text-align: center;
    /* align-self: center; */
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 90%;
  }

  .connectContainer-2{
    flex-direction: column;
  }

  h1.connect {
    font-size: 26px;
    line-height: 32px;
    align-self: center;
  }

  .connect-share-reward{
    text-align: center;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    margin: auto;

  }
  .ccenteronnectContainer-2{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quotes-container {
    row-gap: 5px;
  }

  .quotes-container > h2 {
    font-size: 18px;
    align-self: center;
  }

  .quotes-container > p.quote {
    font-size: 15px;
  }

  .quotes-container :last-child {
    font-size: 12px;
  }

  .quote {
    min-height: inherit;
  }

  .subContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 15% 0;
    gap: 20px;
    text-align: center;
    max-width: inherit;
  }

  .p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .subContainer > .p {
    font-weight: 500;
  }

  .p > span {
    margin-bottom: -8px;
  }

  .link-to-app{
    border-radius: 17px;
    border: none;
    padding: 6px 12px;
    gap: 5.5px;
    cursor: pointer;
    width: 135px;
    height: 35px;
    margin: 30px 0 0 0;
  }

  .link-to-app-text {
    font-size: 13.2px;
    font-weight: 600;
    font-family: "Inter";
  }
  
  .sub-header2{
    font-family: "Montserrat Alternates";
    font-size: 28px;
    line-height: 34px;
    margin: 80px 0 50px 0;
  }

  .business-button {
    width: 145px;
    height: 35px;
    font-size: 12px;
  }

  .businesss{
    height: 450px;
    width: 100%;
    /* text-align: center; */
  }
  .for-business-container{
    align-items: center;
    text-align: center;
    font-size: 25px;
  }
  .three-arrowss{
    height: 30px;
    width: 100px;
  }
  .h1{
    font-size: 22px;
    margin-left: -10px;
  }
  .p1{
    font-size: 20px;
  }

  .download-link h1 {
    font-family: Montserrat;
  }

  .download-link-paragraph > p {
    font-family: Inter;
    font-size: 16px;
  }

  .feedback-container {
    display: flex;
    flex-direction: column;
  }

  .feedback{
    max-width: 85%;
    margin: auto;
  }

  .feedback-h2 {
    margin: 60px 0 -40px 0;
  }

  .feedback-h2 h2 {
    font-size: 28px;
  }

  .for-business-container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 0px;

  }
  .for-business-container h2{
    text-align: center;
    font-size: 36px;
  }

  .curly-arrow {
    position: relative;
    width: 125px;
    height: auto;
  }

  .btn{
    text-align: center;
    font-size: 18px;
  }
  .hide-p{
    display: none;
  }

}
